body {
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
  max-width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(315deg, rgb(60, 60, 60, 1) 3%, rgb(39, 88, 136) 38%);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
  background-attachment: fixed;
}

::-webkit-scrollbar {
  display: none;
}

.wave {
  background: rgb(255 255 255 / 25%);
  border-radius: 1000% 1000% 0 0;
  position: fixed;
  width: 200%;
  height: 4em;
  animation: wave 10s -3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  bottom: 0;
  left: 0;
  z-index: -1;
}

@keyframes gradient {
  0% {
      background-position: 0% 0%;
  }
  50% {
      background-position: 100% 100%;
  }
  100% {
      background-position: 0% 0%;
  }
}

.wave:nth-of-type(2) {
  bottom: -1.25em;
  animation: wave 18s linear reverse infinite;
  opacity: 0.8;
}

.wave:nth-of-type(3) {
  bottom: -2.5em;
  animation: wave 20s -1s reverse infinite;
  opacity: 0.9;
}

@keyframes wave {
  2% {
      transform: translateX(1);
  }

  25% {
      transform: translateX(-25%);
  }

  50% {
      transform: translateX(-50%);
  }

  75% {
      transform: translateX(-25%);
  }

  100% {
      transform: translateX(1);
  }
}

.wave-top {
  background: rgb(255 255 255 / 25%);
  border-radius: 0 0 1000% 1000%;
  position: fixed;
  width: 200%;
  height: 1em;
  animation: wave-top 10s 3s linear infinite;
  transform: translate3d(0, 0, 0);
  opacity: 0.8;
  top: 0;
  left: 0;
  z-index: -1;
}

@keyframes wave-top {
  2% {
      transform: translateX(1);
  }

  25% {
      transform: translateX(-25%);
  }

  50% {
      transform: translateX(-50%);
  }

  75% {
      transform: translateX(-25%);
  }

  100% {
      transform: translateX(1);
  }
}