.App {
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: white;
  min-height: 100vh;
  display: flex;
  margin: auto;
  padding: 0;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.bottom-menu {
  position: fixed;
  bottom: 2vh;
  right: 3%;
}
